import dayjs from 'dayjs'
import TagManager from 'react-gtm-module'
import { TrialLessonIndexView } from '../types/trialLessons'

// For Google Analytics
declare const window: any

export const GtagUtil = {
  reportTrialLessonReservationCompletion(
    userId: string,
    lesson: TrialLessonIndexView,
    reservationId: string,
    currentUserEmail: string | null,
    intention_level?: number
  ) {
    const eventLabel = dayjs(lesson.starts_at).format('YYYYMMDD')
    const tagManagerArgs = {
      dataLayer: { reservation_id: reservationId },
    }
    TagManager.dataLayer(tagManagerArgs)
    if (this.hasGTag()) {
      window.dataLayer.push({
        event:
          lesson.target === 'expert'
            ? 'reservation_complete_expert'
            : 'reservation_complete',
        event_category: 'trial',
        event_label: eventLabel,
        user_id: userId,
        lesson1: lesson.lesson1,
        trial_lesson_id: lesson.id,
        user_email: currentUserEmail,
        intention_level: intention_level,
      })
    }
  },
  setUserForShowModalLineSync() {
    TagManager.dataLayer({})
    if (this.hasGTag()) {
      window.dataLayer.push({
        event: 'set_user_info',
      })
    }
  },
  beginCheckoutStep2Class() {
    TagManager.dataLayer({})
    if (this.hasGTag()) {
      window.dataLayer.push({
        event: 'begin_checkout_step2_class',
        event_category: 'trial',
      })
    }
  },
  beginCheckoutStep2Consultation() {
    TagManager.dataLayer({})
    if (this.hasGTag()) {
      window.dataLayer.push({
        event: 'begin_checkout_step2_consultation',
        event_category: 'trial',
      })
    }
  },
  beginCheckoutStep3Datetime() {
    TagManager.dataLayer({})
    if (this.hasGTag()) {
      window.dataLayer.push({
        event: 'begin_checkout_step3_datetime',
        event_category: 'trial',
      })
    }
  },
  beginCheckoutStep4Name() {
    TagManager.dataLayer({})
    if (this.hasGTag()) {
      window.dataLayer.push({
        event: 'begin_checkout_step4_name',
        event_category: 'trial',
      })
    }
  },
  beginCheckoutStep4Email() {
    TagManager.dataLayer({})
    if (this.hasGTag()) {
      window.dataLayer.push({
        event: 'begin_checkout_step4_email',
        event_category: 'trial',
      })
    }
  },
  beginCheckoutStep4PhoneNumber() {
    TagManager.dataLayer({})
    if (this.hasGTag()) {
      window.dataLayer.push({
        event: 'begin_checkout_step4_phone_number',
        event_category: 'trial',
      })
    }
  },
  beginCheckoutStep4Password() {
    TagManager.dataLayer({})
    if (this.hasGTag()) {
      window.dataLayer.push({
        event: 'begin_checkout_step4_password',
        event_category: 'trial',
      })
    }
  },
  beginCheckoutStep4PasswordConfirmation() {
    TagManager.dataLayer({})
    if (this.hasGTag()) {
      window.dataLayer.push({
        event: 'begin_checkout_step4_password_confirmation',
        event_category: 'trial',
      })
    }
  },
  hasGTag() {
    return typeof window !== 'undefined'
  },
}
